import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";
import {MetaSwiper} from "./meta-settings.js";

function setHoverAutoplay(swiper) {

    if (window.innerWidth > 1024) {
        swiper.autoplay.stop();
        swiper.el.addEventListener("mouseenter", () => {
           if(!swiper.destroyed){
               swiper.slideNext()
           }
        });
        swiper.el.addEventListener("mouseover", () => {
             if(!swiper.destroyed){
                 swiper.autoplay.start()
             }
        });
        swiper.el.addEventListener("mouseout", () => {
             if(!swiper.destroyed){
                 swiper.autoplay.stop()
             }
        });
    }
}

$(document).ready(function() {

    let isSafari12 = navigator.sayswho === "Safari 12";
    let isSafari13 = navigator.sayswho === "Safari 13";
    let isSafari14 = navigator.sayswho === "Safari 14";
    if (isSafari12) $("body").addClass("safari-12");
    if (isSafari13) $("body").addClass("safari-13");
    if (isSafari14) $("body").addClass("safari-14");

    $(".header__items").hover(
        function() {
            if ($(window).width() > 1025) {
                $(".header__item-list").stop(true, true).slideDown(150);
            }
        },
        function() {
            if ($(window).width() > 1025) {
                $(".header__item-list").stop(true, true).slideUp(150);
            }
        },
    );
    $("body").on("mousemove", (e) => {
        if (!$(e.target).closest(".header__hide").length) {
            if ($(window).width() > 1025) {
                $(".header__item-list").slideUp(150);
            }
        }
    });

    let homePageHeroSection = document.querySelector(".banner");
    if (homePageHeroSection) {
        let vh = window.innerHeight * 0.01;
        homePageHeroSection.style.setProperty("--vh", `${vh}px`);
        window.addEventListener("resize", () => {
            let vh = window.innerHeight * 0.01;
            homePageHeroSection.style.setProperty("--vh", `${vh}px`);
        });
    }


    // Add unique class to header if page is scrolled
    const header = document.getElementsByClassName(`header`)[0];
    const classOnScroll = header.getAttribute("data-class-on-scroll");
    const burger = document.getElementsByClassName(`header__burger`)[0];
    const mobileHeader = document.getElementsByClassName(`mobile-header`)[0];

    const menu = document.getElementsByClassName(`header__menu`)[0];
    const layer = document.getElementsByClassName(`header__layer`)[0];

    const body = document.body;
    const headerCloseWrapper =
        document.getElementsByClassName(`header-close-wrapper`)[0];

    // add FillGap for fixed header, so it will not jump when menu is opened
    if (header.classList.contains("fixed")) {
        scrollLock.addFillGapSelector(".header.fixed");
    }

    // Toggle Menu on burger click function
    function toggleMenu() {
        body.classList.toggle("lock");
        header.classList.toggle("open-menu");
        menu.classList.toggle("active");
        layer.classList.toggle("active");
    }

    // animate total line
    const sectionAnimation = $(".section-animation");

    function animate() {
        if (sectionAnimation.length) {
            sectionAnimation.not(".section-animated").each(function() {
                const th = $(this);

                if (
                    $(window).scrollTop() >=
                    th.offset().top - $(window).height() * 0.4
                ) {
                    th.addClass("section-animated");

                    $(".total__item").each(function() {
                        const th = $(this);
                        const thLineWidth = th.attr("data-line-width") + "%";

                        th.find(".total__line span").css("width", thLineWidth);
                    });
                }
            });
        }
    }

    animate();

    $(window).on("scroll", animate);

    // Burger Menu functionality
    if (burger) {
        burger.addEventListener("click", toggleMenu);
    }
    if (layer) {
        layer.addEventListener("click", toggleMenu);
    }

    if (classOnScroll) {
        let scrollTop = $(window).scrollTop();
        let flag = false;

        if (scrollTop > 0) {
            header.classList.add(classOnScroll);
            flag = !flag;
        }

        window.addEventListener("scroll", function() {
            let scrollTop = $(window).scrollTop();

            if (flag && scrollTop === 0) {
                header.classList.remove(classOnScroll);
                flag = !flag;
            } else if (!flag) {
                header.classList.add(classOnScroll);
                flag = !flag;
            }
        });
    }

    // Header -> hide langs
    $(document).on("click", function(e) {
        const lang = $(".header__lang");

        if (lang.hasClass("active")) {
            if (!lang.is(e.target) && !lang.has(e.target).length) {
                lang.removeClass("active");
            }
        }
    });
    $(".label-item:nth-of-type(2) .btn").addClass("btn-reverse");

    // Header -> show menu

    let onceTrig = true;
    $(window).mousemove(function(e) {
        let target = $(e.target);
        if (
            $(".header__item-list").css("display") == "none" &&
            onceTrig &&
            target.closest(".header__right").length &&
            $(window).width() > 1025
        ) {
            $(".header__item-list").stop(true, true).slideDown(150);
            onceTrig = false;
        }
    });

    // Header -> show/hide mobile menu links
    // $(document).on('click', '.header__item h4', function (e) {
    //    e.preventDefault();
    //    const th = $(this);
    //
    //    $('.header__item').not(th.parent()).removeClass('active').find('.header__item-list').slideUp();
    //    th.parent().toggleClass('active').find('.header__item-list').slideToggle();
    // });

    $(".header__menu .header__item h4 span").click(function(e) {
        if (!$(this).hasClass("active")) {
            $(".header__menu .header__item h4 span").removeClass("active");
            $(".header__menu .header__item-list").slideUp();
            e.preventDefault();
            $(this).addClass("active");
            let list = $(this)
                .closest(".header__item")
                .find(".header__item-list");
            list.slideDown();
        }
    });
    $(".header__menu .header__item h4 span").eq(0).trigger("click");

    // Invalid input
    /*$(document).on("blur", ".form__item input[required]", function() {
        const thBlock = $(this).closest('.form__item');
        $(this).val().trim()
            ? thBlock.removeClass("invalid")
            : thBlock.addClass("invalid");
    });*/

    $(document).on(
        "input",
        ".form__item.invalid input, .form__item.invalid textarea",
        function() {
            $(this).closest(".invalid").removeClass("invalid");
        },
    );

    // init swiper on mobile only
    const initSwiperOnMobile = (
        swiperBlock,
        swiperOptions,
        swipeBreakpoint,
    ) => {
        let swiperEl = document.querySelector(swiperBlock);
        if(swiperEl){
            let breakpoint = window.matchMedia(`(max-width: ${swipeBreakpoint})`),
                swiper;

            const breakpointChecker = () => {
                if (breakpoint.matches) {

                    return (swiper = new Swiper(swiperBlock, swiperOptions));
                } else {
                    if (swiper) {
                        return swiper.destroy(true, true);
                    }
                }
            };

            breakpoint.addListener(breakpointChecker);
            breakpointChecker();
        }

    };

    if ($(".banner__swiper").length) {
        let bannerSwiper = new Swiper(".banner__swiper", {
            slidesPerView: "auto",
            speed: 800,
            autoplay: {
                delay: 1000,
                disableOnInteraction: false,
            },
            breakpoints: {
                320: {
                    slidesPerView: 1,
                },
                370: {
                    slidesPerView: 2,
                },
                550: {
                    slidesPerView: 3,
                },
                1024: {
                    slidesPerView: 4,
                },
                1280: {
                    slidesPerView: "auto",
                },
            },
            on: {
                afterInit: function(swiper) {
                    setHoverAutoplay(swiper);
                },
            },
        });
    }

    // Feedback Swiper
    const feedbackSection = $(".feedback__swiper");
    if (feedbackSection.length) {
        let fbSwiper = document.querySelector('.feedback__swiper')
        let slidesCount = feedbackSection.find(".swiper-slide").length;
        MetaSwiper(fbSwiper , {
            speed: 700,
            observer: true,
            autoplay: {
                delay: 1000,
                disableOnInteraction: false,
            },
            direction: "horizontal",
            rewind: true,
            slidesPerView: "auto",
            mousewheel: {
                releaseOnEdges: true,
                forceToAxis: true,
            },
            navigation: {
                nextEl: ".feedback-swiper-btn-next",
                prevEl: ".feedback-swiper-btn-prev",
            },
            breakpoints: {
                300: {
                    slidesPerView: 8,
                    initialSlide: 0,
                    allowTouchMove: false,
                    spaceBetween: 0,
                    autoplay: false,
                    loop:false,
                    observer: false,
                },

                651: {
                    spaceBetween: 36.453,
                    loop: slidesCount > 2,
                    loopedSlides: 4,
                },
                1026: {
                    spaceBetween: 40,
                    loop: slidesCount > 1,
                },
                1920: {
                    spaceBetween: 40,
                    loop: slidesCount > 2,
                },
            },
            on: {
                afterInit(swiper) {
                    if(window.innerWidth > 650){
                        setHoverAutoplay(swiper);
                    }
                },
            },
        })

    }

    // case-studies swiper
    const caseStudiesBlock = document.querySelectorAll(".case-studies-block");
    if (caseStudiesBlock.length && !$(".different-section-about-us").length) {
        caseStudiesBlock.forEach((section) => {
            const swiperSlider = section.querySelector(".case-studies__swiper");
            const nextEl = section.querySelector(".swiper-btn-next");
            const prevEl = section.querySelector(".swiper-btn-prev");
            const slidesCount = swiperSlider.querySelectorAll(".swiper-slide").length;
            MetaSwiper(swiperSlider, {
                speed: 700,
                observer: true,
                spaceBetween: 16,
                slidesPerView: "auto",
                autoplay: {
                    delay: 1000,
                    disableOnInteraction: false,
                },
                mousewheel: {
                    releaseOnEdges: true,
                    forceToAxis: true,
                },
                navigation: {
                    nextEl: nextEl,
                    prevEl: prevEl,
                },
                breakpoints: {
                    300: {
                        slidesPerView: 8,
                        initialSlide: 0,
                        allowTouchMove: false,
                        spaceBetween: 0,
                        autoplay: false,
                        loop:false,
                        observer: false,
                    },
                    651: {
                        loop: slidesCount > 2,
                    },
                    1025: {
                        centeredSlides: false,
                        loop: slidesCount > 2,
                    },
                    1919: {
                        slidesPerView: 3,
                        loop: slidesCount > 3,
                    },
                },
                on: {
                    afterInit(swiper) {
                        if(window.innerWidth > 650){
                            setHoverAutoplay(swiper);
                        }
                    },
                },
            })
        });
    } else if ($(".different-section-about-us").length) {
        caseStudiesBlock.forEach((section) => {
            const swiperSlider = section.querySelector(".case-studies__swiper");
            const nextEl = section.querySelector(".swiper-btn-next");
            const prevEl = section.querySelector(".swiper-btn-prev");
            const slidesCount = swiperSlider.querySelectorAll(".swiper-slide").length;
            MetaSwiper(swiperSlider, {
                speed: 700,
                observer: true,
                spaceBetween: 12,
                slidesPerView: "auto",
                autoplay: {
                    delay: 1000,
                    disableOnInteraction: false,
                },
                navigation: {
                    nextEl: nextEl,
                    prevEl: prevEl,
                },
                mousewheel: {
                    releaseOnEdges: true,
                    forceToAxis: true,
                },
                breakpoints: {
                    300: {
                        slidesPerView: 5,
                        initialSlide: 0,
                        allowTouchMove: false,
                        spaceBetween: 0,
                        autoplay: false,
                        loop:false,
                        observer: false,
                    },
                    651: {

                        loop: slidesCount > 3,
                    },
                    1025: {

                        loop: slidesCount > 4,
                    },
                    1300: {
                        loop: slidesCount > 6,
                    },
                },
                on: {
                    afterInit(swiper) {
                        if(window.innerWidth > 650){
                            setHoverAutoplay(swiper);
                        }
                    },
                },
            })
        });
    }

    // sectors swiper
    const sectorsSection = Array.from(
        document.querySelectorAll(".sectors-section"),
    );
    let sectorsSwiper = document.querySelector('.sectors-swiper');
    if (sectorsSection && sectorsSwiper) {
        sectorsSection.forEach((section) => {
            const swiperSlider = section.querySelector(".swiper");
            const slidesCount = section.querySelectorAll(".swiper-slide").length;
            const nextEl = section.querySelector(".sectors-swiper-btn-next");
            const prevEl = section.querySelector(".sectors-swiper-btn-prev");
            MetaSwiper(swiperSlider, {
                initialSlide: 1,
                speed: 1000,
                observer: true,
                spaceBetween: 16,
                slidesPerView: "auto",
                autoplay: {
                    delay: 1000,
                    disableOnInteraction: false,
                },
                mousewheel: {
                    releaseOnEdges: true,
                    forceToAxis: true,
                },
                navigation: {
                    nextEl: nextEl,
                    prevEl: prevEl,
                },
                breakpoints: {
                    300: {
                        slidesPerView: 8,
                        initialSlide: 0,
                        allowTouchMove: false,
                        spaceBetween: 0,
                        autoplay: false,
                    },
                    651: {
                        loop: slidesCount > 2,
                        allowTouchMove: slidesCount > 2,
                        centeredSlides: false,
                        navigation: {
                            enabled: slidesCount > 2,
                        },
                    },
                    992: {
                        centeredSlides: false,
                        loop: slidesCount > 3,
                        allowTouchMove: slidesCount > 3,

                        navigation: {
                            enabled: slidesCount < 3,
                        },
                    },

                    1300: {
                        centeredSlides: false,
                        loop: slidesCount > 4,
                        allowTouchMove: slidesCount > 4,
                        navigation: {
                            enabled: slidesCount > 4,
                        },
                    },
                    1700: {
                        centeredSlides: false,
                        slidesPerView: "auto",
                        autoplay:
                            slidesCount >= 6
                                ? {
                                    delay: 1000,
                                    disableOnInteraction: false,
                                }
                                : false,
                        loop: slidesCount >= 6,
                        allowTouchMove: slidesCount >= 6,
                        navigation: {
                            enabled: slidesCount >= 6,
                        },
                    },
                },
                on: {
                    afterInit(swiper) {
                        if(window.innerWidth > 650){
                            setHoverAutoplay(swiper);
                        }
                    },
                    breakpoint: function(swiper){
                        let breakpoint = this.currentBreakpoint;
                        let loop = this.passedParams.breakpoints[breakpoint].loop;
                        let wrapper = swiperSlider.querySelector('.swiper-wrapper');
                        if(!loop){
                            wrapper.classList.add('justify-center');
                        }else{
                            wrapper.classList.remove('justify-center');
                        }
                    }
                },
            })

        });
    }

    // hiring swiper
    const hiringSwiper = $(".hiring-swiper");
    if (hiringSwiper.length) {
        let videoPlayersArr = [];
        let hrSwiper = document.querySelector('.hiring-swiper');
        const slideCount = hiringSwiper.find(".swiper-slide").length;
        let autoplayOption = { delay: 1000, disableOnInteraction: false};
        MetaSwiper(hrSwiper,{
            initialSlide: 1,
            speed: 1000,
            observer: true,
            spaceBetween: 16,
            autoplay: autoplayOption,
            mousewheel: {
                releaseOnEdges: true,
                forceToAxis: true,
            },
            breakpoints: {
                320: {
                    slidesPerView: 2,
                    loop: slideCount > 2,
                    allowTouchMove: slideCount > 2,
                    autoplay: slideCount > 2 ? autoplayOption : false,
                    centeredSlides: false,
                },
                451: {
                    slidesPerView: 3.25,
                    loop: slideCount > 3,
                    allowTouchMove: slideCount > 3,
                    autoplay: slideCount > 3 ? autoplayOption : false,
                    centeredSlides: slideCount > 3,
                },
                768: {
                    slidesPerView: "auto",
                    loop: slideCount > 2,
                    allowTouchMove: slideCount > 2,
                    autoplay: slideCount > 2 ? autoplayOption : false,
                    centeredSlides: slideCount > 2,
                },
                1200: {
                    slidesPerView: 3.8,
                    loop: slideCount > 3,
                    allowTouchMove: slideCount > 3,
                    autoplay: slideCount > 3 ? autoplayOption : false,
                    centeredSlides: slideCount > 4,
                },
                1800: {
                    slidesPerView: 4,
                    centeredSlides: slideCount > 4,
                    loop: slideCount > 4,
                    autoplay: slideCount > 4 ? autoplayOption : false,
                    allowTouchMove: slideCount > 4,
                },
                2000: {
                    slidesPerView: 5.5,
                    loop: slideCount > 5,
                    allowTouchMove: slideCount > 5,
                    autoplay: slideCount > 5 ? autoplayOption : false,
                    centeredSlides: slideCount > 5,
                },
            },
            on: {
                afterInit: function(swiper) {
                    setHoverAutoplay(swiper);

                    //plyr video init
                    let hiringVideoArr = Array.from(
                        document.querySelectorAll(".hiring-card-thumbnail video"),
                    );

                    if(hiringVideoArr){
                        hiringVideoArr.forEach((video) => {
                            const player = new Plyr(video, {
                                controls: ["play-large"],
                            });
                            const videoWrapper = video.parentElement;
                            videoWrapper.addEventListener("click", (event) => {
                                player.togglePlay();
                                event.stopPropagation(); // Necessary or the video will toggle twice => no playback
                            });

                            player.toggleControls(false);
                            player.on('play', function(){
                                swiper.autoplay.stop();
                            })
                            player.on('stop', function(){
                                swiper.autoplay.start();
                            })
                            videoPlayersArr.push(player);
                        });
                    }
                },
                slideChange: function(swiper){
                    videoPlayersArr.forEach(player=>{
                        player.pause();
                    })
                }
            },
        })

    }

    // Total Swiper
    /*const totalSection = $(".total");
    if (totalSection) {
        const totalInitSwiperBreakpoint = totalSection.attr(
            "data-init-swiper-on",
        );
        const totalSwiperOptions = {
            speed: 700,
            observer: true,
            autoplay: {
                delay: 1000,
                disableOnInteraction: false,
                // pauseOnMouseEnter: true,
            },
            navigation: {
                nextEl: ".swiper-btn-next",
                prevEl: ".swiper-btn-prev",
            },
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 36.453,
                },
                651: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                },
                901: {
                    slidesPerView: 2,
                    spaceBetween: 60,
                },
            },
            on: {
                afterInit(swiper) {
                    setHoverAutoplay(swiper);
                },
            },
        };

        // init swiper on mobile only
        initSwiperOnMobile(
            ".total__swiper",
            totalSwiperOptions,
            totalInitSwiperBreakpoint,
        );
    }*/

    // Insights Swiper
    const insightsSection = document.querySelector(".insights");
    if (insightsSection) {
        let swiper = insightsSection.querySelector('.insights__swiper')
        let slidesCount = insightsSection.querySelectorAll(".swiper-slide").length;
        let prevEl = insightsSection.querySelector(".swiper-btn-prev");
        let nextEl = insightsSection.querySelector(".swiper-btn-next");
        MetaSwiper(swiper, {
            speed: 700,
            observer: true,
            autoplay: {
                delay: 1000,
                disableOnInteraction: false,
                // pauseOnMouseEnter: true,
            },
            mousewheel: {
                releaseOnEdges: true,
                forceToAxis: true,
            },
            navigation: {
                nextEl: nextEl,
                prevEl: prevEl,
            },
            breakpoints: {
                300: {
                    slidesPerView: 8,
                    initialSlide: 0,
                    allowTouchMove: false,
                    spaceBetween: 0,
                    autoplay: false,
                },
                651: {
                    slidesPerView: "auto",
                    spaceBetween: 36.453,
                    centeredSlides: false,
                    loop: slidesCount > 2,
                },
                1026: {
                    slidesPerView: "auto",
                    spaceBetween: 50,
                    loop: slidesCount > 1,
                },
                1920: {
                    slidesPerView: "auto",
                    spaceBetween: 50,
                    loop: slidesCount > 3,
                },
            },
            on: {
                afterInit(swiper) {
                    if(window.innerWidth >650){
                        setHoverAutoplay(swiper);
                    }
                },
            },
        })
    }

    // Journey Swiper
    const journeySwiper = $(".journey-swiper");
    if (journeySwiper.length) {
        let swiper = document.querySelector('.journey-swiper');
        MetaSwiper(swiper, {
            speed: 800,
            observer: true,
            loop: true,
            autoplay: {
                delay: 1000,
                disableOnInteraction: false,
                // pauseOnMouseEnter: true,
            },
            mousewheel: {
                releaseOnEdges: true,
                forceToAxis: true,
            },
            centeredSlides: true,
            navigation: {
                nextEl: ".journey-next-btn",
                prevEl: ".journey-prev-btn",
            },
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 36.453,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                },
            },
            on: {
                afterInit(swiper) {
                    setHoverAutoplay(swiper);
                },
            },
        })
    }

    // Case cards Swiper
    const caseCardsSwiper = $(".case-cards-swiper");
    if (caseCardsSwiper.length) {
        let swiper = document.querySelector('.case-cards-swiper')
        MetaSwiper(swiper, {
            speed: 1000,
            observer: true,
            spaceBetween: 16,
            autoplay: {
                delay: 1000,
                disableOnInteraction: false,
                // pauseOnMouseEnter: true,
            },
            mousewheel: {
                releaseOnEdges: true,
                forceToAxis: true,
            },
            navigation: {
                nextEl: ".case-cards-next-btn",
                prevEl: ".case-cards-prev-btn",
            },
            breakpoints: {
                300: {
                    slidesPerView: 8,
                    initialSlide: 0,
                    allowTouchMove: false,
                    spaceBetween: 0,
                    autoplay: false,
                    loop:false,
                    observer: false,
                },
                651: {
                    slidesPerView: "auto",
                    loop: true,
                    centeredSlides: true,
                },
                1280: {
                    slidesPerView: 4,
                },
            },
            on: {
                afterInit(swiper) {
                    setHoverAutoplay(swiper);
                },
            },
        })
    }

    // Footer Swiper
    const footerSection = $(".footer");
    if (footerSection.length) {
        const footerInitSwiperBreakpoint = footerSection.attr(
            "data-init-swiper-on",
        );
        const footerSwiperOptions = {
            speed: 700,
            observer: true,
            autoplay: {
                delay: 1000,
                disableOnInteraction: false,
                // pauseOnMouseEnter: true,
            },
            mousewheel: {
                releaseOnEdges: true,
                forceToAxis: true,
            },
            navigation: {
                nextEl: ".footer-swiper-btn-next",
                prevEl: ".footer-swiper-btn-prev",
            },
            breakpoints: {
                310: {
                    spaceBetween: 5,
                    slidesPerView: 2,
                },
                551: {
                    spaceBetween: 27,
                    slidesPerView: 3,
                },
                767: {
                    spaceBetween: 27,
                    slidesPerView: 4,
                },
                901: {
                    spaceBetween: 27,
                    slidesPerView: 5,
                },
                1026: {
                    spaceBetween: 27,
                    slidesPerView: 6,
                },
            },
            on: {
                afterInit(swiper) {
                    setHoverAutoplay(swiper);
                },
            },
        };

        // init swiper on mobile only
        initSwiperOnMobile(
            ".footer__swiper",
            footerSwiperOptions,
            footerInitSwiperBreakpoint,
        );
    }

    // Work at  Swiper
    const workAtSwiper = $(".work-at-swiper");
    if (workAtSwiper.length) {
        let swiper = document.querySelector('.work-at-swiper')
        MetaSwiper(swiper, {
            speed: 700,
            effect: "fade",
            autoHeight: true,
            fadeEffect: {
                crossFade: true,
            },
            mousewheel: {
                releaseOnEdges: true,
                forceToAxis: true,
            },
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            navigation: {
                nextEl: ".work-at-next-btn",
                prevEl: ".work-at-prev-btn",
            },
        })
    }

    //show map info
    function showMapInfo(button) {
        let infoBox = $(button).find(".info-box");
        if ($(window).width() > 1024) {
            if (
                infoBox.hasClass("show-info") &&
                !button.hasClass("active-index")
            ) {
                infoBox.removeClass("show-info").stop(true, true).fadeOut();
                $(".info-box-bg").stop(true, true).fadeOut();
                $(".active-index").removeClass("active-index");
                scrollLock.enablePageScroll();
            } else {
                if ($(window).width() <= 1024 && scrollLock.getScrollState())
                    scrollLock.disablePageScroll();
                $(button).addClass("active-index");
                $(".info-box-bg").stop(true, true).fadeIn();
                infoBox.stop(true, true).fadeIn({
                    start: function() {
                        $(".show-info")
                            .removeClass("show-info")
                            .stop(true, true)
                            .fadeOut();
                        $(this).addClass("show-info");
                    },
                });
            }
        } else {
            $(".map-info-popup").remove();
            $("body").append("<div class='map-info-popup footer__map'></div>");
            $(".map-info-popup")
                .stop(true, true)
                .fadeIn({
                    start: function() {
                        $(this).append(infoBox.clone());
                        $(this).append($(".info-box-bg.close-info").clone());
                        scrollLock.disablePageScroll();
                    },
                });
        }
    }

    // close info if click on map
    function hideMapInfo() {
        $(".show-info").removeClass("show-info").stop(true, true).fadeOut();
        $(".info-box-bg").stop(true, true).fadeOut();
        scrollLock.enablePageScroll();
        $(".active-index").stop(true, true).removeClass("active-index");
        $(".map-info-popup")
            .stop(true, true)
            .fadeOut({
                complete: function() {
                    $(this).remove();
                },
            });
    }

    let isTouch = "ontouchstart" in window || navigator.msMaxTouchPoints > 0;
    if (isTouch || $(window).width() <= 1024) {
        $(".label-item").click(function() {
            showMapInfo(this);
        });
    } else {
        $(".label-item").hover(
            function() {
                showMapInfo(this);
            },
            function(e) {
                hideMapInfo();
            },
        );
    }

    $("body").on("click", ".footer__map", function(e) {
        let target = $(e.target);
        if (
            !target.closest(".btn").length &&
            !target.closest(".info-box").length &&
            !target.hasClass("info-box")
        ) {
            hideMapInfo();
        }
    });

    $("body").on("click", ".close-info", function() {
        hideMapInfo();
    });

    //page builder video
    $(".article-thumbnail video").each((ind, el) => {
        let elId = `plyr-video-item-${ind}`;
        $(el).attr("id", elId);
        let target = `#${elId}`;
        const player = new Plyr(target, {
            controls: ["play-large"],
        });
    });

    // teammate swiper
    const teammateSwiper = document.querySelectorAll(".team-swiper");

    if (teammateSwiper) {
        teammateSwiper.forEach((el, ind) => {
            let reverseDir = false;
            let defaultDelay = 5000;
            let defaultSpeed = 1000;
            if ($(".team-swiper-reverse").length) {
                defaultSpeed = defaultDelay;
                defaultDelay = 500;
            }
            el.classList.contains("team-swiper-reverse")
                ? (reverseDir = true)
                : (reverseDir = false);

            const parentSection = el.closest(".team-swiper-section");
            const slidesCount = el.querySelectorAll(".swiper-slide").length;
            MetaSwiper(el, {
                speed: defaultSpeed,
                observer: true,
                spaceBetween: 10.4,
                autoplay: {
                    delay: 1000,
                    disableOnInteraction: false,
                },
                mousewheel: {
                    releaseOnEdges: true,
                    forceToAxis: true,
                },
                breakpoints: {
                    300: {
                        slidesPerView: 8,
                        initialSlide: 0,
                        allowTouchMove: false,
                        spaceBetween: 0,
                        autoplay: false,
                        loop:false,
                        observer: false,
                    },

                    651: {
                        slidesPerView: 3,
                        loop: slidesCount > 3,
                        centeredSlides: slidesCount <= 3,
                        allowTouchMove: slidesCount > 3,
                    },
                    750: {
                        slidesPerView: 4,
                        loop: slidesCount > 4,
                        centeredSlides: slidesCount <= 4,
                        allowTouchMove: slidesCount > 4,
                    },
                    1280: {
                        slidesPerView: 5,
                        loop: slidesCount > 5,
                        allowTouchMove: slidesCount > 5,
                    },
                    on: {
                        afterInit(swiper) {
                            if(window.innerWidth >650){
                                setHoverAutoplay(swiper);
                            }
                        },
                    },
                },
            })

            if ($(".team-swiper-reverse").length) {
                let transformLeft =
                    $(el).find(".swiper-slide").eq(0).width() / 2 + "px";
                $(".team-swiper-reverse").css(
                    "transform",
                    `translateX(${transformLeft})`,
                );
            }
        });
    }

    //upload cv button handler

    let file = document.querySelector("[type=\"file\"]");
    if (file) {
        file.addEventListener("change", function() {
            $(".upload-status").text(this.files[0].name);
        });
    }
    const select2 = $(".select-2-item").select2({
        placeholder: " ",
        width: "100%",
    });
    select2.on("select2:select", function(e) {
        $(e.currentTarget).closest(".form__item").removeClass("invalid");
        console.log("close");
    });
    //style validate

    // vertical layout total items section
    let maxSwipertotalItmeHeight = 0;
    let maxSwipertotalItmeTitleHeight = 0;
    $(".total .swiper-slide").each((ind, el) => {
        if (
            maxSwipertotalItmeHeight < $(el).find(".total__item").eq(0).height()
        ) {
            maxSwipertotalItmeHeight = $(el)
                .find(".total__item")
                .eq(0)
                .height();
        }
        if (
            maxSwipertotalItmeTitleHeight <
            $(el).find(".total__item h4").eq(1).height()
        ) {
            maxSwipertotalItmeTitleHeight = $(el)
                .find(".total__item h4")
                .eq(1)
                .height();
        }
    });
    $(".total .swiper-slide .total__item:first-child").css(
        "min-height",
        maxSwipertotalItmeHeight,
    ); //sets to all first items the same height
    $(".total .swiper-slide .total__item:nth-of-type(2) h4").css(
        "min-height",
        maxSwipertotalItmeTitleHeight,
    ); //sets to all second items the same title height

    //footer swiper line
    $(".footer__offices").append(
        "<div class=\"footer-swiper-line js-initialized\"></div>",
    );

    function swiperLinePosition() {
        if ($(".footer-swiper-line").length) {
            let positionTop = 0;
            $(".footer__slide h4").each(function(ind, el) {
                if (positionTop <= $(el).outerHeight()) {
                    positionTop = $(el).outerHeight();
                }
                $(el).css("min-height", positionTop + "px");
            });

            $(".footer-swiper-line").css("top", positionTop);
        }
    }

    $(window).on("orientationchange", swiperLinePosition());

    /* page builder section connection handlers start */
    if (true) {
        $("section").each((ind, el) => {
            if (!$(".page-builder").length) return;
            let childSectionClipPath = $(el).find(".clip-section")[0];
            let hasSectionClipPath =
                $(el).hasClass("clip-section") ||
                $(el).find(".clip-section").length;
            let hasNextSectionClipPath =
                $(el).next("section").hasClass("clip-section") ||
                $(el).next().find(".clip-section").length;

            if (!hasSectionClipPath) {
                if (!$(el).find(".section-bg-initialized").length) {
                    $(el).append("<div class='section-bg-initialized'></div>");
                }
            }

            /*
            one - <section> or children element has clip-path value
            zero - <section> or children element has not clip-path value
            zeroZero means that current <section> has not clip-path value and next section too
            zeroOne means that current <section> has not clip-path value BUT next section has clip-path value
            oneZero means that current <section> has clip-path value BUT next section has not clip-path value
            oneOne means that current <section> has clip-path and next section has clip-path value too
            */
            let zeroZero = !hasSectionClipPath && !hasNextSectionClipPath;
            let zeroOne = !hasSectionClipPath && hasNextSectionClipPath;
            let oneOne = hasSectionClipPath && hasNextSectionClipPath;
            let oneZero = hasSectionClipPath && !hasNextSectionClipPath;
            let yTop = getComputedStyle($(el)[0]).getPropertyValue("--ytop");
            let yBot = getComputedStyle($(el)[0]).getPropertyValue("--ybot");
            let nextIsHidden = !$(el).next('section').is(':visible');
            let beforeHiddenHasNext = ( nextIsHidden && $(el).next().next().length);
            let currentSectionHidden = !$(el).is(':visible');

            if(currentSectionHidden){
                $(el).addClass('hidden-initialized');
                // return;
            }
            if(beforeHiddenHasNext){
                $(el).addClass('prev-hidden-initialized')
            }

            if (parseFloat(yBot) === 0) yBot = "0px";

            if (oneZero) {
                if (!$(el).next().find(".section-bg-initialized").length) {
                    $(el)
                        .next()
                        .append(`<div class="section-bg-initialized"></div>`);
                }
                let nextSectionBgLayer = $(el)
                    .next()
                    .find(".section-bg-initialized")
                    .eq(0);
                let newTop = `calc((${yBot} + ${$(el).css(
                    "margin-bottom",
                )}) * -1)`;
                let newHeight = `calc(100% + ${yBot} + ${$(el).css(
                    "margin-bottom",
                )})`;
                $(el).addClass("one-to-zero-initialized");
                $(el).css("z-index", 1);
                nextSectionBgLayer.css({
                    height: newHeight,
                    top: newTop,
                });
            }

            if (zeroOne) {

                yTop = getComputedStyle($(el).next()[0]).getPropertyValue(
                    "--ytop",
                );

                if(!$(el).next('section').is(':visible') && $(el).next().next().length){
                    yTop = getComputedStyle($(el).next().next()[0]).getPropertyValue(
                        "--ytop",
                    );
                    $(el).addClass('before-hidden-initialized');
                }
                if(!$(el).next('section').is(':visible') && !$(el).next().next().length){
                    $(el).addClass('last-section-initialized');
                    return;
                }

                if (parseFloat(yTop) === 0) yTop = "0px";
                let currentHeight = $(el)
                    .find(".section-bg-initialized")
                    .eq(0)
                    .height();
                let heightDiff = currentHeight - $(el).innerHeight();
                let nextSectionMarginTop = $(el).next().css("margin-top");
                let newHeight = `calc(100% + ${heightDiff}px + ${yTop} + ${nextSectionMarginTop})`;


                $(el).addClass("zero-to-one-initialized");
                $(el).css("z-index", 0);
                $(el).find(".section-bg-initialized").eq(0).css({
                    height: newHeight,
                });
            }

            if (oneOne) {
                let ytop = getComputedStyle($(el).next()[0]).getPropertyValue(
                    "--ytop",
                );
                let nextSectionMarginTop = parseFloat(
                    $(el).next().css("margin-top"),
                );
                let parseMarginTop =
                    nextSectionMarginTop < 0
                        ? `- ${Math.abs(nextSectionMarginTop)}px`
                        : `+ ${nextSectionMarginTop}px`;
                let newPaddingBottom = `${ytop}`;
                let newMarginBottom = `calc((2px + ${ytop} ${parseMarginTop}) * -1) `;
                $(el).addClass("one-to-one-initialized");

                if ((!$(el).next("section").is(":visible") && $(el).next(".contact").length) ||(!$(el).next("section").is(":visible") && $(el).next(".insights").length)) {
                    //need when next section is hide to correct displaying
                    $(el).css({
                        "padding-bottom": newPaddingBottom,
                        "z-index": 1,
                    });

                } else {
                    $(el).css({
                        "--y2": "0px", //clear bottom clip-path value
                        "--y3": "0px", //clear bottom clip-path value
                        "padding-bottom": newPaddingBottom,
                        "margin-bottom": newMarginBottom,
                        "z-index": 1,
                        "--pb-initialized": newPaddingBottom,
                    });
                }

                $(el).find(".bottom-triangle").css("display", "none");

                $(el).next().css("z-index", 2);
            }

            //fix for hero section if it is not first section and has js background
            if ($(el).find(".hero-section").length && $(el).find(".section-bg-initialized").length) {
                let jsBg = $(el).find(".section-bg-initialized");
                let pb = getComputedStyle($(el).next()[0]).getPropertyValue("--ytop");
                let positionTop = Math.abs(parseFloat(jsBg.position().top));
                console.log("positionTop=", positionTop);
                $(el).css("padding-bottom", pb);
                $(el).find(".section-bg").css({
                    "top": jsBg.position().top,
                    "height": `calc(100% + ${positionTop}px + ${pb} + ${pb})`,
                });
            }

            /*if (zeroZero) {
            }*/
        });
    }
    /* page builder section connection handlers end */
    // meet the team search input handler
    $(".meet-the-team-page .search__form input").on("keydown", function(e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            load("about/team_search", "form:#form-search");
        }
    });

    /* page builder section connection handlers end*/


    let userAgent = navigator.userAgent.toLowerCase();
    let iphone = /iphone/gi.test(userAgent);
    let ipad = /ipad/gi.test(userAgent);
    if (iphone || ipad) {
        $(".jobs__info").each((ind, el) => {
            new SimpleBar(el, {
                autoHide: false,
            });
        });
    }

    // show / hide filters on meet the team page
    $(".search-filters-toggle").click(function() {
        $(this).toggleClass("active");
        $(this).next("form").stop(true, true).slideToggle();
    });

    /**/

}); //end of ready
